import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactHero from "../components/contactHero";
import Contact from "../components/contact";

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Quintin Orsmond" />
    <ContactHero></ContactHero>
    <Contact></Contact>
  </Layout>
)

export default ContactPage
